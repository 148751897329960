*{
  font-family: 'Euphoria Script', cursive;
}
.bad-text{
  font-family: 'Bad Script', cursive;
}
.bigger-text{
  font-size: 25px;
}
body {
  background-image: url("./pages/sun.jpg");
}
#top{
  margin: 100px 0;
}
.main-link{
  font-size: x-large;
}
.gal{
  max-height: 300px;
  max-width: 300px;
}
.horizontalScroll{
  max-height: 300px;
  max-width: 100%;
  display: flex;
  overflow: auto;
}

.horizontalScroll::-webkit-scrollbar {
  display: none;
}
.announcement-card{
  min-width: 300px;
  max-width: 300px;
  min-height: fit-content;
  
}

.horizontalAnnouncements{
  min-height: fit-content;
  max-width: 100%;
  display: flex;
  overflow: auto;
}

.horizontalAnnouncements::-webkit-scrollbar {
  display: none;
}
.category{
  font-weight: bold;
  border-bottom: solid thin lightgray;
}
.product{
  background-color: transparent;
  border:none;
  border-radius: 0%;
  /* border-top: 1px dashed; */
}
.hours{
  font-size: 200%;
}
.large{
  height: fit-content;
  width: 80%;
  font-size: x-large;
}
.announcement{
  font-size: 20px;
}
.footer-button{
  width: fit-content;
  height: 100%;

}
.footer{
  background: transparent;
}
.ig-link{
  color:purple;
}
.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}
